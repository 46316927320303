<template>
    <div class="order-search-container">
        <div class="order-search-container-top">
            <van-nav-bar title="申领管理" fixed/>
        </div>
        <div class="order-search-container-search">
            <van-search placeholder="搜索一下" :disabled="true" show-action @click="picker.search=true">
                <div slot="action" @click="add">新增申领</div>
            </van-search>
        </div>
        <div class="order-search-container-list">
            <van-pull-refresh v-model="freshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="finishedText"
                        @load="onLoad"
                >
                    <van-swipe-cell v-for="(item ,index) in list" v-bind:key="index">
                        <van-cell :border="true" :value="item.userName" :label="item.customer" @click="detail(item)">
                            <template slot="title">
                                <!--申请中-->
                                <van-tag round plain type="danger" v-if="item.status==1">
                                    {{item.statusName}}
                                </van-tag>
                                <!--已审核-->
                                <van-tag round plain type="primary" v-else-if="item.status==2">
                                    {{item.statusName}}
                                </van-tag>
                                <!--已发卡-->
                                <van-tag round plain type="success" v-else>
                                    {{item.statusName}}
                                </van-tag>
                                <!--未回款-->
                                <van-tag round plain type="danger" v-if="item.returnStatus==0">
                                    {{item.returnStatusName}}
                                </van-tag>
                                <!--部分回-->
                                <van-tag round plain type="primary" v-else-if="item.returnStatus==1">
                                    {{item.returnStatusName}}
                                </van-tag>
                                <!--已回款-->
                                <van-tag round plain type="success" v-else>
                                    {{item.returnStatusName}}
                                </van-tag>
                                <!--为开票-->
                                <van-tag round plain type="danger" v-if="item.invoiceStatus==0">
                                    {{item.invoiceStatusName}}
                                </van-tag>
                                <!--部分开-->
                                <van-tag round plain type="primary" v-else-if="item.invoiceStatus==1">
                                    {{item.invoiceStatusName}}
                                </van-tag>
                                <!--已开票-->
                                <van-tag round plain type="success" v-else>
                                    {{item.invoiceStatusName}}
                                </van-tag>
                                <span class="custom-title">{{item.id}}</span>
                            </template>
                        </van-cell>
                        <template slot="right">
                            <van-button square type="primary" icon="edit" text="修改" @click="edit(item)" v-if="item.designee === 0 && item.status === 1"/>
                            <van-button square type="warning" icon="my iconfont icon-fanshen" text="反审" @click="rollbackOrder(item)" v-if="item.status > 1 && auth.indexOf('/sale/apply/rollback') >= 0"/>
                            <van-button square type="info" icon="success" text="审核" @click="auditOrder(item)" v-if="item.audit === 1 && item.status === 1 && item.vipCard !== 1 && item.collectionDays < 180 && auth.indexOf('/sale/apply/audit') >= 0"/>
                            <van-button square type="info" icon="success" text="审核" @click="auditOrder(item)" v-if="item.audit === 1 && item.status === 1 && item.vipCard !== 1 && item.collectionDays >= 180 && auth.indexOf('/sale/apply/auditsenior') >= 0"/>
                            <van-button square type="info" icon="success" text="审核" @click="auditOrder(item)" v-if="item.status === 1 && item.vipCard === 1 && item.vipAudit === 1"/>
							<van-button square type="danger" icon="delete" text="删除" @click="deleteOrder(item)" v-if="item.designee === 0 && item.status === 1"/>
                        </template>
                    </van-swipe-cell>
                </van-list>
            </van-pull-refresh>
        </div>
        <div class="order-search-container-pagination" v-show="pages>0">
            <van-pagination
                    v-model="option.page"
                    :page-count="pages"
                    mode="simple"
                    @change="onLoad"
            />
        </div>
        <!-- 弹出区域 -->
        <div class="order-search-container-popup">
            <!-- 搜索 -->
            <van-popup v-model="picker.search" position="right" :round="true" :style="{width:'80%',height:'100%'}">
                <van-cell-group>
                    <van-field
                            v-model="option.id"
                            label="申领单号"
                            placeholder="请输入申领单号"
                            clearable
                    />
                    <van-field
                            v-model="option.customer"
                            label="客户名称"
                            placeholder="请输入客户名称"
                            clearable
                    />
                    <van-field
                            v-model="option.applyDate"
                            label="申领日期"
                            placeholder="请选择申领日期"
                            @focus="picker.applyDate=true"
                            readonly
                    />
                    <van-field
                            v-model="option.statusName"
                            label="申领状态"
                            placeholder="请选择申领状态"
                            @focus="picker.status=true"
                            readonly
                    />
                    <van-field
                            v-model="option.returnStatusName"
                            label="回款状态"
                            placeholder="请选择回款状态"
                            @focus="picker.returnStatus=true"
                            readonly
                    />
                    <van-field
                            v-model="option.invoiceStatusName"
                            label="开票状态"
                            placeholder="请选择开票状态"
                            @focus="picker.invoiceStatus=true"
                            readonly
                    />
                    <van-field
                            v-show="deptList.length > 2"
                            v-model="option.deptName"
                            label="所属部门"
                            placeholder="请选择所属部门"
                            @focus="picker.dept=true"
                            readonly
                    />
                    <van-field
                            v-show="deptList.length > 2"
                            v-model="option.userName"
                            label="销售员"
                            placeholder="请选择销售员"
                            @focus="onPopupUser"
                            readonly
                    />
                    <van-button type="info" icon="passed" size="large" @click="onSearch"
                                style="position:fixed;bottom:0;"
                                text="查询"/>
                </van-cell-group>
            </van-popup>
            <!-- 申领日期 -->
            <van-popup v-model="picker.applyDate" position="bottom" :style="{height:'50%'}">
                <van-datetime-picker
                        v-model="currentDate"
                        type="date"
                        cancel-button-text="清除"
                        @cancel="onClearApplyDate"
                        @confirm="onChangeApplyDate"
                />
            </van-popup>
            <!-- 申领状态 -->
            <van-popup v-model="picker.status" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="申领状态"
                        :columns="statusList"
                        @confirm="onChangeStatus"
                        @cancel="picker.status=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 申领状态 -->
            <van-popup v-model="picker.returnStatus" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="回款状态"
                        :columns="returnStatusList"
                        @confirm="onChangeReturnStatus"
                        @cancel="picker.returnStatus=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 申领状态 -->
            <van-popup v-model="picker.invoiceStatus" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="开票状态"
                        :columns="invoiceStatusList"
                        @confirm="onChangeInvoiceStatus"
                        @cancel="picker.invoiceStatus=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 所属部门 -->
            <van-popup v-model="picker.dept" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="所属部门"
                        :columns="deptList"
                        @confirm="onChangeDept"
                        @cancel="picker.dept=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 销售员 -->
            <van-popup v-model="picker.user" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="销售员"
                        :columns="userList"
                        @confirm="onChangeUser"
                        @cancel="picker.user=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 反审 -->
            <van-popup v-model="picker.rollback" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="反审状态"
                        :columns="rollbackStatus"
                        @confirm="onRollback"
                        @cancel="picker.rollback=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 申领单详情 -->
            <van-popup v-model="picker.detail" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}" get-container="#app">
                <orderDetail :param.sync="param" :key="param.id" @onClose="onDelete" v-if="param.id > 0"/>
            </van-popup>
            <!-- 新增修改申领单 -->
            <van-popup v-model="picker.edit" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}">
                <addOrder :param.sync="param" :key="param.id" @onClose="onModifyOrder"/>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import orderDetail from './detail'
    import addOrder from './input'

    export default {
        name: "apply-search",
        components: {
            orderDetail,
            addOrder,
        },
        data() {
            return {
                freshing: false,
                auth: this.$store.state.user.auth || [],
                list: [],
                statusList: [
                    {index: '', text: '全部'},
                    {index: 1, text: '申请中'},
                    {index: 2, text: '已审核'},
                    {index: 3, text: '已发卡'}
                ],
                returnStatusList: [
                    {index: '', text: '全部'},
                    {index: 0, text: '未回款'},
                    {index: 1, text: '部分回'},
                    {index: 2, text: '已回款'}
                ],
                invoiceStatusList: [
                    {index: '', text: '全部'},
                    {index: 0, text: '未开票'},
                    {index: 1, text: '部分开'},
                    {index: 2, text: '已开票'}
                ],
                rollbackStatus: [
                    {index: 1, text: '申请中'},
                    {index: 2, text: '已审核'}
                ],
                deptList: [],
                userList: [],
                userListCache: [],
                loading: false,
                finished: true,
                finishedText: '',
                pages: 0,
                option: {
                    page: 1,
                    id: '',
                    customer: '',
                    applyDate: '',
                    status: '',
                    statusName: '全部',
                    returnStatus: '',
                    returnStatusName: '全部',
                    invoiceStatus: '',
                    invoiceStatusName: '全部',
                    deptId: 0,
                    deptName: '全部',
                    userId: 0,
                    userName: '全部',
                },
                picker: {
                    search: false,
                    dept: false,
                    user: false,
                    detail: false,
                    edit: false,
                    status: false,
                    returnStatus: false,
                    invoiceStatus: false,
                    applyDate: false,
                    rollback: false,
                },
                param: {id: 0},
                currentDate: new Date(),
                rollbackItem: {}
            }
        },
        mounted: function () {
            this.init()
        },
        methods: {
            init() {
                this.option.applyDate = this.nowDate(new Date());
                this.loadDept();
                this.onLoad();
            },
            loadDept() {
                let that = this;
                if (that.deptList.length == 0) {
                    that.$api.user.loadDept().then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            res.data.unshift({index: 0, text: '全部'});
                            that.deptList = res.data;
                        }
                    });
                }
            },
            onClearApplyDate() {
                this.option.applyDate = '';
                this.picker.applyDate = false;
            },
            onChangeApplyDate(value) {
                this.option.applyDate = this.nowDate(value);
                this.picker.applyDate = false;
            },
            onChangeStatus(value) {
                this.picker.status = false;
                this.option.status = value.index;
                this.option.statusName = value.text;
            },
            onChangeReturnStatus(value) {
                this.picker.returnStatus = false;
                this.option.returnStatus = value.index;
                this.option.returnStatusName = value.text;
            },
            onChangeInvoiceStatus(value) {
                this.picker.invoiceStatus = false;
                this.option.invoiceStatus = value.index;
                this.option.invoiceStatusName = value.text;
            },
            onChangeDept(value) {
                // 清空已选销售员
                if (this.option.deptId !== value.index) {
                    this.option.userId = 0;
                    this.option.userName = '全部';
                }
                this.picker.dept = false;
                this.option.deptId = value.index;
                this.option.deptName = value.text;
            },
            onPopupUser() {
                if (this.option.deptId == 0)
                    return this.$toast.fail('请选择所属部门');
                let that = this;
                let cache = [];
                if (this.userListCache.length > 0) {
                    this.userListCache.forEach(function (v) {
                        if (v.deptId == that.option.deptId) {
                            cache = v.list;
                        }
                    })
                }
                if (cache.length == 0) {
                    that.$api.user.loadUserByDept(that.option.deptId).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            res.data.unshift({index: 0, text: '全部'});
                            that.userListCache.push({deptId: that.option.deptId, list: res.data});
                            that.userList = res.data;
                            that.picker.user = true;
                        } else {
                            that.$toast(res.msg);
                        }
                    })
                } else {
                    this.userList = cache;
                    this.picker.user = true;
                }
            },
            rollbackOrder(item) {
                this.rollbackItem = item;
                this.picker.rollback = true;
            },
            onChangeUser(value) {
                this.picker.user = false;
                this.option.userId = value.index;
                this.option.userName = value.text;
            },
            onRollback(value) {
                let that = this;
                if (value.index == that.rollbackItem.status)
                    return that.$toast('状态未变更');
                let info = {
                    id: that.rollbackItem.id,
                    status: value.index
                };
                that.$api.apply.rollback(info).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.picker.rollback = false;
                        that.onLoad();
                    } else {
                        that.$toast(res.msg);
                    }
                })

            },
            onSearch() {
                this.option.page = 1;
                this.onLoad();
            },
            onRefresh() {
                this.option.page = 1;
                this.freshing = false;
                this.onLoad();
            },
            onLoad() {
                let that = this;
                that.picker.search = false;
                that.list = [];
                that.loading = true;
                that.finished = false;
                that.$api.apply.search(that.option).then(response => {
                    that.loading = false;
                    that.finished = true;
                    let res = response.data;
                    if (res.code == 1) {
                        if (res.data.list.length == 0) {
                            that.finishedText = '暂无数据'
                        } else {
                            that.finishedText = ''
                        }
                        that.pages = res.data.pages;
                        that.list = res.data.list;
                    } else {
                        that.$toast(res.msg);
                    }
                })
            },
            detail(item) {
                this.param.id = item.id;
                this.picker.detail = true;
            },
            add() {
                this.param.id = 0;
                this.picker.edit = true;
            },
            edit(item) {
                this.param.id = item.id;
                this.picker.edit = true;
            },
            onDelete() {
                this.picker.detail = false;
                this.onLoad();
            },
            onModifyOrder() {
                this.picker.edit = false;
                this.option.applyDate = this.nowDate(new Date());
                this.onSearch();
            },
            auditOrder(item) {
                let that = this;
                that.$dialog.confirm({
                    message: '确认审核吗？'
                }).then(() => {
                    that.$api.apply.audit(item).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.$toast.success(res.msg);
                            that.onLoad();
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                });
            },
            deleteOrder(item) {
                let that = this;
                that.$dialog.confirm({
                    message: '操作不可逆，确认删除吗？'
                }).then(() => {
                    that.$api.apply.delete(item).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.$toast.success(res.msg);
                            that.onLoad();
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                });
            },
            nowDate(now) {
                let date = now ? now : new Date();
                let seperator = '-';
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                if (month >= 1 && month <= 9) {
                    month = '0' + month;
                }
                if (day >= 0 && day <= 9) {
                    day = '0' + day;
                }
                return year + seperator + month + seperator + day;
            }
        }
    }
</script>

<style scoped>
    .order-search-container {
        padding-top: 46px;
    }

    .van-cell__title {
        flex: 3
    }

    .van-cell__title .van-tag {
        margin-right: 4px;
    }

    .van-swipe-cell .van-button {
        height: 100%;
    }

    .order-search-container-top {
        position: fixed;
        width: 100%;
        height: 46px;
        top: 0;
    }

    .order-search-container-list {
        position: fixed;
        width: 100%;
        top: 100px;
        bottom: 40px;
        overflow-y: scroll;
    }

    .order-search-container-pagination {
        position: fixed;
        width: 100%;
        height: 40px;
        bottom: 0;
    }
</style>
